$(function () {
    $('.main-slider').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        slide: '.slide',
        dots: true
    });

    $('.other-products-slider').slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        slide: '.product-box',
        dots: true
    });

    $('.blog-slider').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        slide: '.blog-post',
        dots: true,
        arrows: false
    });

    $('.mobile-menu-opener').click(function () {
        $('header').toggleClass('menu-active')
    })
})